.our-story {
    padding: 120px 0 100px 0;    
}

.tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    background: linear-gradient(90.21deg, #aa367c80 -5.91%, #4a2fbd80 100%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 20px;
    margin-bottom: 16px;
    display: inline-block;
    font-family: 'Jost', sans-serif;
}

.our-story h1 {
    font-size: 55px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
    font-family: 'IBM Plex Serif', serif;
}

.our-story p {
    font-size: 17px;
    line-height: 1.5em;
    width: 96%;
    font-family: 'Jost', sans-serif;
}

.story-image {
    width: 100%;
}

@media (max-width: 951.98px) {
    .our-story h1 {
        font-size: 40px;
    }

    .tagline {
        font-size: 16px;
    }
}