.about-banner {
  padding: 100px 0 100px 0;
  text-align: center;
  background-image: url("../../images/Story/Image.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.story-icons {
  height: 4rem;
  margin-bottom: 2rem;
}
.story-icons-small {
  height: 3rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.story-icons-up {
  height: 4rem;
  margin-top: -2.5rem;
  margin-bottom: 2rem;
}

.about-banner h1 {
  font-family: "IBM Plex Serif", serif;
  color: rgb(60, 61, 55);
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 5rem;
}

.about-banner span {
  font-family: "IBM Plex Serif", serif;
  color: rgb(131, 67, 55);
  font-size: 50px;
  font-weight: 700;
}

.about-banner h5 {
  font-family: "Jost", sans-serif;
  color: rgb(130, 60, 47);
  letter-spacing: 1.5px;
  font-size: 18px;
  font-weight: 600;
}

.about-banner h2 {
  color: rgb(130, 60, 47);
  font-family: "IBM Plex Serif", serif;
  font-size: 25px;
  font-weight: 600;
  white-space: nowrap;
}

.about-banner p {
  text-align: left;
  font-size: 17px;
  font-family: "Jost", sans-serif;
  font-weight: 400;
  width: 100%;
  margin-bottom: 5rem;
}

.about-banner .locBtn {
  text-decoration: none;
  color: rgb(156, 116, 71);
  font-size: 1.1rem;
  font-weight: 800;
  letter-spacing: 2px;
}

.about-banner .locBtn-container {
  border: none;
  background: none;
}


@media (max-width: 951.98px) {
  .about-banner h1 {
    font-size: 40px;
  }

  .about-banner span {
    font-size: 40px;
  }
}