nav.navbar {
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
  position: fixed;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: rgb(253, 250, 244);
}

.logo {
  height: 80px;
  align-self: center;
}

.logo-dark {
  height: 80px;
  align-self: center;
  padding: 0px 0;
  margin-top: -8px;
}

.logo-link {
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
  margin-top: -1.5rem;
}

.logo-center {
  justify-content: center;
  align-items: center;
}

.nav-menus {
  margin-left: 5rem;
  margin-right: 5rem;
}

.navbar-link {
  font-family: "Jost", sans-serif;
  font-size: large !important;
  font-weight: 700 !important;
  white-space: nowrap;
  color: rgb(253, 250, 244) !important;
}

.navbar-link-scrolled {
  font-family: "Jost", sans-serif;
  font-size: large !important;
  font-weight: 700 !important;
  white-space: nowrap;
  color: rgb(39, 18, 17) !important;
}

.navbar-link:hover,
.navbar-link.active {
  opacity: 1;
}

.mt3 {
  margin-top: 1rem;
}

.collapsed-bg {
}

nav.navbar .navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid white;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: white;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler-icon-dark {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid black;
  transition: all 300ms linear;
  top: -10px;
  display: inline-block;
}

nav.navbar .navbar-toggler-icon-dark:after,
nav.navbar .navbar-toggler-icon-dark:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: black;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon-dark:after {
  top: 8px;
}

nav.navbar .navbar-toggler .navbar-collapse .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: white;
  height: 2px;
}

nav.navbar .navbar-toggler [aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: white;
  height: 2px;
}

nav.navbar .navbar-toggler [aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

@media (max-width: 991.98px) {
  .mt3 {
    margin-top: 3rem;
  }
  .collapsed-bg {
    background-color: rgba(0, 0, 0, 0.5);
    border: black;
    border-radius: 25px;
  }
  .collapsed-bg-dark {
    border-radius: 25px !important;
  }

  nav.navbar.scrolled {
    padding: 20px 0;
  }

  .logo-dark {
    height: 80px;
    align-self: center;
    margin-top: -18px;
  }
}

@media (max-width: 1270px) {
  .nav-menus {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    font-family: "Jost", sans-serif;
    font-size: large;
    font-weight: 700;
    white-space: nowrap;
    color: rgb(253, 250, 244);
  }
}
