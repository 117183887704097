.hero {
    width: 100%;
    height: 90vh;
    position: relative;
}

.hero-mid {
    width: 100%;
    height: 70vh;
    position: relative;
}

.hero-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
}

.hero-text h1 {
    font-size: 5rem;
    font-weight: 800;
    color: rgb(253, 250, 244);
    font-family: 'IBM Plex Serif', serif;
}

.hero-text h5 {
    font-size: 1.5rem;
    font-weight: 600; 
    color: rgb(253, 250, 244);
    font-family: 'Jost', sans-serif;
    padding-bottom: 1.5rem;
}

.hero-text p {
    font-size: 2rem;
    padding: 2rem 0 2rem 0;

}

.hero-text .show {
    text-decoration: none;
    background: rgb(156, 116, 71);
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: 800;
    letter-spacing: 2px;
    color: white;
}

.hero-text .none {
    display: none;
}

@media (max-width: 991.98px) {
    .hero-text h1 {
        font-size: 3rem;
        padding: 10px 20px;
    }

    .hero-text h5 {
        font-size: 1.3rem;
    }

    .hero-text p {
        font-size: 1.1rem;
        padding: 0 0 2rem 0;
    }

    .hero-text .show {
        padding: 0.6rem 1.1rem;
        border-radius: 6px;
        font-size: 1rem;
    }
}