.menu-card {
    width: 32%;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    border-radius: 7px;
    padding: 1rem 1rem;
    text-align: center;
}

.menu-card h4 {
    font-size: 1.3rem;
    padding: 0.9rem 0 0.2rem 0;
    /* margin-left: 2rem; */
    margin-bottom: -0.5rem;
    font-family: "Jost", sans-serif;
    /* color: rgb(130, 60, 47); */
    letter-spacing: 1.5px;
    /* font-size: 18px; */
    font-weight: 600;
    /* text-align: center; */
}

.menu-card-image {
    /* height: 500px; */
    overflow: hidden;
    border-radius: 7px;
}

.menu-card-image img {
    width: 100%;
    /* height: 100%; */
    transition: 0.3s ease-in-out;
    border-radius: 7px;
}

@media (max-width: 575.98px) {
    .menu-card {
        width: 100%;
        margin-bottom: 1.5rem;
        border: none;
        box-shadow: none;
    }

    .menu-card-image {
        /* height: 500px; */
        overflow: hidden;
        border-radius: 7px;
        /* width: 50%; */
    }

    .menu-card-image img {
        width: 80%;
        /* height: 100%; */
        transition: 0.3s ease-in-out;
        border-radius: 7px;
    }

}