.last-desc .locBtn {
    text-decoration: none;
    /* background-color: rgb(156, 116, 71); */
    /* padding: 1rem 2rem; */
    /* border-radius: 6px; */
    /* color: white; */
    color: rgb(156, 116, 71);
    font-size: 1.1rem;
    font-weight: 800;
    letter-spacing: 2px;
  }
  
  .last-desc .locBtn-container {
    border: none;
    background: none;
    /* justify-content: center; */
    
  }