.google-map {
  flex-grow: 1;
}

.map-container {
  display: flex;
}

.location {
  margin-top: 2rem;
  padding: 120px 0 100px 0;
  text-align: left;
}

.location h5 {
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  font-family: "Jost", sans-serif;
}

.location p {
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 1;
  font-family: "IBM Plex Serif", serif;
}

.location .wrap {
  float: right;
}

.location .menuBtn {
  text-decoration: none;
  color: rgb(156, 116, 71);
  font-size: 1.1rem;
  font-weight: 800;
  letter-spacing: 2px;
}

.location .menuBtn-container {
  border: none;
  background: none;
}

.date-container {
  margin-bottom: 3rem;
}

@media (max-width: 1200px) {
  .text-container {
    justify-content: center;
  }
  .location h5 {
    padding-top: 3rem;
    text-align: center;
  }

  .address {
    text-align: center;
  }

  .date-container {
    padding: 1rem;
  }

  .location .menuBtn {
    transform: translateX(-50%);
    left: 50%;
    position: absolute;
  }
}
