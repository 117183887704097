.description {
    margin: 4rem 6rem;
}

.description h1 {
    /* font-size: 3rem; */
    font-family: 'IBM Plex Serif', serif;
    color: rgb(131, 67, 55);
    font-size: 50px;
    font-weight: 700;

}

.description h2 {
    /* color: rgb(130, 60, 47); */
    font-family: 'IBM Plex Serif', serif;
    font-size: 40px;
    font-weight: 600;
    padding-top: 5rem;
    /* white-space: nowrap; */
}

.description h4 {
    font-family: 'IBM Plex Serif', serif;
    font-size: 18px;
    font-weight: 600;
}

.description h5 {
    font-family: "Jost", sans-serif;
    /* color: rgb(130, 60, 47); */
    letter-spacing: 1.5px;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 4rem;
    margin-top: 1rem;
}

.description h6 {
    font-family: 'IBM Plex Serif', serif;
    font-size: 15px;
    padding-bottom: 2rem;
}

.des-text {
    width: 45%;
    text-align: start;
    /* font-size: 1.1rem; */
    font-size: 16px;
    /* text-align: left; */
    /* font-size: 17px; */
    font-family: "Jost", sans-serif;
    font-weight: 400;
    /* width: 100%; */
    margin-bottom: 5rem;
}

.description .image {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    z-index: -99;
}

.description .image img {
    width: 49%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}

.description .image img:nth-child(2) {
    position: absolute;
    top: -10%;
    right: 0;
}

.first-des {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
}

.first-des-reverse {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
}

.none {
    display: none;
}

@media (max-width: 951.98px) {
    .description {
        margin: 4rem 2rem;
    }

    .first-des {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 2.5rem;
    }

    .first-des-reverse {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 3.5rem;
    }

    .des-text {
        width: 100%;
    }

    .description .image {
        width: 100%;
        margin: 1.8rem 0;
    }

    .description .image img {
        height: 250px;
    }

    .description h1 {
        font-size: 40px;
    }

    .description h2 {
        font-size: 30px;
    }
}
