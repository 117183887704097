.home-menu {
    padding: 4rem 6rem;
    text-align: center;
    background-color: white;
}

.home-menu h1 {
    font-family: 'IBM Plex Serif', serif;
    color: rgb(131, 67, 55);
    font-size: 50px;
    font-weight: 700;
}

.home-menu h2 {
    font-family: 'IBM Plex Serif', serif;
    font-size: 40px;
    font-weight: 600;
    padding-top: 5rem;
}

.home-menu-card {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
}

.home-menu .fullMenuBtn {
    text-decoration: none;
    /* background-color: rgb(156, 116, 71); */
    /* padding: 1rem 2rem; */
    /* border-radius: 6px; */
    /* color: white; */
    color: rgb(156, 116, 71);
    font-size: 1.1rem;
    font-weight: 800;
    letter-spacing: 2px;
  }
  
  .home-menu .fullMenuBtn-container {
    border: none;
    background: none;
    /* justify-content: center; */
    padding-top: 3rem;
  }

  @media (max-width: 575.98px) {
    .home-menu {
        margin: 4rem 2rem;
        padding: 0;
    }

    .home-menu h1 {
        font-size: 40px;
    }

    .home-menu h2 {
        font-size: 30px;
    }

    .home-menu-card {
        flex-direction: column;
    }

    .home-menu .fullMenuBtn-container {

        /* justify-content: center; */
        padding-bottom: 3rem;
        padding-top: 0;
      }

}